import { t } from "i18next";
import { ChangeEvent, useMemo, useState } from "react";
import { Dropdown } from "../components/Dropdown";
import { FileSelector } from "../components/FileSelector";
import { HtmlRenderer } from "../components/HtmlStringRenderer";
import { TextArea } from "../components/TextArea";
import DropdownItem from "../models/DropdownItem";
import GithubFile from "../models/GithubFile";
import { fetchGhRepoData, getStyleSheetData } from "../services/GitHubService";
import { getAllBundleIds, getBundleData } from "../services/HapiService";
import { applyXslToFile } from "../utils/XmlUtils";

export const HomePage = () => {

    const [styledXmlAsString, setStyledXmlAsString] = useState<string>();
    const [xmlAsString, setXmlAsString] = useState<string>();
    const [githubFiles, setGithubFiles] = useState<GithubFile[]>([]);
    const [bundlesIds, setBundlesIds] = useState<string[]>([]);
    const [selectedStyleSheet, setSelectedStyleSheet] = useState<Document>();
    const [activeButtonIndex, setActiveButtonIndex] = useState(0);


    useMemo(() => {
        fetchGhRepoData()
            .then(files => setGithubFiles(files))
        getAllBundleIds()
            .then(result => setBundlesIds(result))
    }, []);

    function handleFileSelection(fileContent?: string) {
        if (!fileContent) {
            setStyledXmlAsString(undefined);
            setXmlAsString(undefined)
            return;
        }
        setXmlAsString(fileContent)
        renderStyledHtml(fileContent, selectedStyleSheet);
    }

    function handleTexrArea(e: ChangeEvent<HTMLTextAreaElement>): void {
        setXmlAsString(e.target.value)
        renderStyledHtml(e.target.value, selectedStyleSheet);
    }

    function handleGithubFileSelection(download_url?: string): void {
        if (!download_url) {
            setSelectedStyleSheet(undefined);
            setStyledXmlAsString(undefined);
            return;
        }

        getStyleSheetData(download_url).then(styleSheet => {
            setSelectedStyleSheet(styleSheet);
            renderStyledHtml(undefined, styleSheet);
        })
    }

    function handleBundleIdSelection(id?: string): void {
        if (!id) {
            setSelectedStyleSheet(undefined);
            setStyledXmlAsString(undefined);
            return;
        }

        getBundleData(id).then(xml => {
            setXmlAsString(xml);
            renderStyledHtml(xml, undefined);
        })
    }

    function renderStyledHtml(xml?: string, styleSheet?: Document): void {
        if (!xml) {
            xml = xmlAsString
        }

        if (!styleSheet) {
            styleSheet = selectedStyleSheet
        }

        if (xml && styleSheet) {
            const styledFile = applyXslToFile(xml, styleSheet)
            setStyledXmlAsString(styledFile);
        }
    }

    return (
        <div className="grid grid-cols-2 gap-4">
            <div className="py-2">

                <Dropdown
                    defaultDisplay="Select stylesheet"
                    items={githubFiles
                        .filter(file => file.download_url && file.name)
                        .map(file => new DropdownItem(file.download_url, file.name))}
                    onGithubFileSelected={(download_url) => handleGithubFileSelection(download_url)} />

                <ul className="flex items-center gap-2 text-sm font-medium py-2">
                    <li className="flex-1">
                        <button
                            onClick={() => {
                                setStyledXmlAsString(undefined)
                                setXmlAsString(undefined)
                                setActiveButtonIndex(0)
                            }}
                            className={(activeButtonIndex === 0 ? "bg-gray-300" : "bg-gray-100") + " flex w-full items-center justify-center gap-2 rounded-lg px-3 py-2 text-gray-700 hover:bg-gray-300"}>
                            {t("Upload file") as string}
                        </button>
                    </li>
                    <li className="flex-1">
                        <button
                            onClick={() => {
                                setStyledXmlAsString(undefined)
                                setXmlAsString(undefined)
                                setActiveButtonIndex(1)
                            }}
                            className={(activeButtonIndex === 1 ? "bg-gray-300" : "bg-gray-100") + " flex w-full items-center justify-center gap-2 rounded-lg px-3 py-2 text-gray-700 hover:bg-gray-300"}>
                            {t("Fetch from HAPI") as string}
                        </button>
                    </li>
                </ul>
                {activeButtonIndex === 0 ? (<FileSelector onFileSelected={(fileContent) => handleFileSelection(fileContent)} expectedFileType=".xml" />
                ) : (
                    <Dropdown
                        defaultDisplay="Select Hapi bundle id"
                        items={bundlesIds.map(id => new DropdownItem(id, id))}
                        onGithubFileSelected={(id) => handleBundleIdSelection(id)} />
                )}
                <TextArea value={xmlAsString ?? ""} handleChange={(e) => handleTexrArea(e)} />

            </div>

            {styledXmlAsString && styledXmlAsString != "" &&
                <div>
                    <HtmlRenderer html={styledXmlAsString} />
                </div>
            }
        </div>
    )
}