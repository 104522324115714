import React, { useState } from 'react';
import { ErrorResponse, UploadError, useUploadUrlMutation } from '../services/adminApi';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText, TextField, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const UploadUrl: React.FC = () => {
    const [url, setUrl] = useState('');
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [uploadErrors, setUploadErrors] = useState<UploadError[]>([]);
    const [uploadUrl] = useUploadUrlMutation();

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUrl(event.target.value)
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        if (url) {
            try {
                await uploadUrl(url).unwrap();
                console.log('File uploaded successfully');
                setUrl('');
            } catch (error) {
                if (isErrorResponse(error) && error.status === 422) {
                    setUploadErrors(error.data);
                    setErrorDialogOpen(true);
                } else {
                    console.error('Error uploading file:', error);
                }
            }
        } else {
            console.error('Please select a file to upload.');
        }
    };

    const handleClose = () => {
        setErrorDialogOpen(false);
        setUploadErrors([]);
    };

    return (
        <>
        
        <Box component="form" onSubmit={handleSubmit} display="flex" flexDirection="column" gap={2} mb={4}>
            <Typography>Installer fra URL</Typography>
            <TextField
                type="text"
                //inputProps={{ accept: '.tgz' }}
                onChange={handleFileChange}
                required
            />
            <Button type="submit" variant="contained" color="primary">
                Upload Fil fra URL
            </Button>
        </Box>

        <Dialog open={errorDialogOpen} onClose={handleClose}>
        <DialogTitle>Error Uploading File</DialogTitle>
        <DialogContent>
            <List>
                {uploadErrors.map((error, index) => (
                    <ListItem key={index}>
                        <ListItemText
                            primary={`Filename: ${error.filename}`}
                            secondary={`Error: ${error.message}`}
                        />
                    </ListItem>
                ))}
            </List>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="primary">Close</Button>
        </DialogActions>
        </Dialog>
        </>
        
    );
};

// Type guard to check if the error is an ErrorResponse
function isErrorResponse(error: any): error is ErrorResponse {
    return typeof error === 'object' && error !== null && 'status' in error && 'data' in error;
}

export default UploadUrl;
