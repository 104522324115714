import { t } from "i18next";
import { ChangeEvent, useMemo, useState } from "react";
import { Dropdown } from "../components/Dropdown";
import { FileSelector } from "../components/FileSelector";
import { HtmlRenderer } from "../components/HtmlStringRenderer";
import { TextArea } from "../components/TextArea";
import DropdownItem from "../models/DropdownItem";
import GithubFile from "../models/GithubFile";
import { fetchGhRepoData, getStyleSheetData } from "../services/GitHubService";
import { getAllBundleIds, getBundleData } from "../services/HapiService";
import { applyXslToFile } from "../utils/XmlUtils";

import DataGridTable from "../components/DataGridTable";
import InputForm from "../components/InputForm";
import UploadForm from "../components/UploadForm";
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography } from "@mui/material";
import PackageSelector from "../components/PackageSelector";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UploadUrl from "../components/UploadUrl";

export const IgAdminPage = () => {

   return (
    <>

        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          divider={
            <Box
              component="hr"
              sx={{
                border: (theme) =>
                  `1px solid ${theme.palette.mode === 'dark' ? '#262B32' : '#fff'}`,
              }}
            />
          }
          spacing={4}
        >

          <PackageSelector></PackageSelector>
          <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>Andre installationsmuligheder</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <UploadForm />
                <UploadUrl />
                </AccordionDetails>
        </Accordion>
            {/* <InputForm />
            <UploadForm /> */}
            


            <DataGridTable />
        </Stack>
    </>
    )
}