
interface HtmlRendererProps {
    html: string
}

export const HtmlRenderer = (props: HtmlRendererProps) => {

    return (
        <div className="py-2">
            <div className="border-2 rounded-lg border-gray-300 p-2">
                <div className="flex items-center justify-center">
                    <div className="m-auto" dangerouslySetInnerHTML={{ __html: props.html }} />
                </div>
            </div>
        </div>

    )
}