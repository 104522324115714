import axios from "axios";
import { t } from "i18next";
import { toast } from "react-toastify";
import { Bundle } from "../models/Bundle";

export const getAllBundleIds = async (): Promise<string[]> => {
    try {
        const { data: bundles } = await axios.get<Bundle>(
            `${process.env.REACT_APP_HAPI_URL}/fhir/Bundle`)
        const result = bundles.entry.filter(entry => entry.resource && entry.resource.id).map(entry => entry.resource.id)
        return result;
    } catch {
        const errorMessage = t("Cound not git bundle ids from Hapi");
        toast.error(errorMessage)
        return [];
    }
}

export const getBundleData = async (id: string): Promise<string | undefined> => {
    try {
        const { data: response } = await axios
            .get<string>(`${process.env.REACT_APP_HAPI_URL}/fhir/Bundle/${id}`,
                {
                    headers: {
                        accept: 'application/fhir+xml',
                    }
                })
        return response;

    } catch {
        const errorMessage = t("Cound not git bundle ids from Hapi");
        toast.error(errorMessage)
    }
}