import { t } from "i18next";
import DropdownItem from "../models/DropdownItem";

interface DropdownProps {
    defaultDisplay: string
    items: DropdownItem[]
    onGithubFileSelected: (download_url?: string) => void
}

export const Dropdown = (props: DropdownProps) => {

    function handleChange(value?: string) {
        if (!value) {
            props.onGithubFileSelected(undefined);
            return;
        }

        props.onGithubFileSelected(value);
    }

    return (
        <div>
            <select
                defaultValue=""
                onChange={(e) => handleChange(e.target.value)}
                className="block w-full text-sm py-2.25 text-gray-800 border-2 rounded-lg border-gray-300 bg-white">
                <option value='' disabled>{t(props.defaultDisplay) as string}</option>
                {props.items.map(item => (
                    <option key={item.name} value={item.value}>{item.name}</option>
                ))
                }
            </select>
        </div>
    )
}