import { Box, CssBaseline } from "@mui/material";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { SideMenu } from "./SideMenu";
import { TopBar } from "./Topbar";

type LayoutProps = {
    children: JSX.Element
}


const Layout = (props: LayoutProps) => {
    const drawerWidth = 240;

    return (
         <>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <TopBar drawerWidth={drawerWidth}></TopBar>
                <SideMenu drawerWidth={drawerWidth}></SideMenu>
                <Box
                    component="main"
                    sx={{ marginTop: 10, flexGrow: 1, bgcolor: 'background.default', p: 3 }}
                >
                    {props.children}
                    {/*
                    <AutoBreadcrump />
                    {props.children}
                    <MuiXLicense />
                    */}
                </Box>
            </Box>
        </>
    )
}

export default Layout;