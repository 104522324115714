import { t } from 'i18next';
import { toast } from 'react-toastify';

export function applyXslToFile(file: string, xslt: Document): string | undefined {
    try {
        const proccessor = new XSLTProcessor();
        if (xslt) {
            proccessor.importStylesheet(xslt);
        }

        let xmlDocument = undefined;
        xmlDocument = new window.DOMParser().parseFromString(file, "text/xml")
        return proccessor.transformToDocument(xmlDocument).documentElement.outerHTML;

    } catch {
        const errorMessage = t("Could not apply stylesheet to xml");
        toast.error(errorMessage)
    }
}