
import { StrictMode } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import Layout from './components/Layout/Layout';
import { HomePage } from './pages/home';
import { IgAdminPage } from './pages/igAdmin';

function App() {

    return (
        <Router>
            <Layout>
                <>
                    <Routes>
                        <Route path="/home" element={<HomePage />} />
                        <Route path="/admin" element={<IgAdminPage />} />
                    </Routes>
                </>
            </Layout>
        </Router >
    )
}

const AppWrapper = () => {
    return (
        <StrictMode>
            <App />
        </StrictMode>
    )
}


export default AppWrapper;
