import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "Could not apply stylesheet to xml": "Kunne ikke anvende det valgte stylesheet til xml",
      "Could not fetch stylesheets from github": "Kunne ikke hente stylesheets fra github",
      "Could not download stylesheet": "Kunne ikke hente det valgte stylesheet",
      "Github repo includes directories": "Github repo indeholder foldere, deres indhold vil ikke blive vist her",
      "Github repo includes files that are not stylesheets": "Github repo indeholder files som ikke er stylesheets, de vil ikke blive vist her",
      "Cound not git bundle ids from Hapi": "Kunne ikke hente bindle id'er fra Hapi",
      "Upload file": "Upload fil",
      "Fetch from HAPI": "Hent fra HAPI",
      "Select Hapi bundle id": "Vælg et Hapi bundle id",
      "Select stylesheet": "Vælg et stylesheet",
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;