import getEnvironment from '../env';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export interface Package {
    id: string;
    versions: string[];
}
export interface Option {
    id?: string;
    versions?: string[];
}
export interface ImplementationGuide {
    id?: string;
    version?: string;
    created?: string;
    current?: string;
}

export interface UploadError {
    filename: string;
    message: string;
}

export interface ErrorResponse {
    status: number;
    data: UploadError[];
}

const baseurl = getEnvironment().REACT_APP_API_BASEURL;
console.log("aaaa:", baseurl)

export const adminApi = createApi({
    reducerPath: 'adminApi',
    baseQuery: fetchBaseQuery({ baseUrl: `${baseurl}` }),
    tagTypes: ['ImplementationGuide', 'ImplementationGuides'],
    endpoints: (builder) => ({
        getPackages: builder.query<Package[], boolean>({
            query: (useBuildServer) => `/implementationguides/available?useBuildServer=${useBuildServer}`,
        }),
        getPackageVersions: builder.query<Option, string>({
            query: (id) => `/implementationguides/${id}/versions/available`,
        }),
        getImplementationGuides: builder.query<ImplementationGuide[], void>({
            query: () => '/implementationguides',
            providesTags: ['ImplementationGuides']
        }),
        updateImplementationGuideVersion: builder.mutation<void, { packageId: string; packageVersion: string }>({
            query: ({ packageId, packageVersion }) => ({
                url: `${baseurl}/implementationguides/${packageId}/versions/${packageVersion}`,
                method: 'POST',
                //body: { selectedOptionId },
                
            }),
            invalidatesTags: ['ImplementationGuides']
        }),
        deleteImplementationGuideVersion: builder.mutation<void, { id: string; version: string; }>({
            query: ({ id, version }) => ({
                url: `${baseurl}/implementationguides/${id}/versions/${version}`,
                method: 'DELETE',
                //body: { selectedOptionId },
                
            }),
            invalidatesTags: ['ImplementationGuides']
        }),
        uploadFile: builder.mutation<void, File>({
            query: (file) => ({
                url: '/implementationguides/upload/file',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/octet-stream',
                },
                body: file,
                
            }),
            invalidatesTags: ['ImplementationGuides']
        }),
        uploadUrl: builder.mutation<void, string>({
            query: (url) => ({
                url: '/implementationguides/upload/url',
                method: 'POST',
                headers: {
                    'Content-Type': 'text/plain',
                },
                body: url,
                
            }),
            invalidatesTags: ['ImplementationGuides']
        }),
    }),
});

export const { useGetPackagesQuery, useGetPackageVersionsQuery, useGetImplementationGuidesQuery, useUpdateImplementationGuideVersionMutation, useDeleteImplementationGuideVersionMutation, useUploadFileMutation, useUploadUrlMutation } = adminApi;
