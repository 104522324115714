import axios from 'axios'
import { t } from 'i18next'
import { toast } from 'react-toastify'
import GithubFile from '../models/GithubFile'

export const fetchGhRepoData = async (): Promise<GithubFile[]> => {
    try {
        const { data: response } = await axios.get<GithubFile[]>(process.env.REACT_APP_FHIR_STYLESHEETS_GH_API_URL ?? "")
        const githubFiles = response;

        if (githubFiles.filter(file => file.type !== "file").length > 0) {
            const warningMessage = t("Github repo includes directories")
            toast.warn(warningMessage, { toastId: "dir-warn" });
        }

        if (githubFiles.filter(file => !file.name?.includes(".xsl")).length > 0) {
            const warningMessage = t("Github repo includes files that are not stylesheets")
            toast.warn(warningMessage, { toastId: "invalid-files-warn" });
        }

        return githubFiles.filter(file => file.type === "file" && file.name?.includes(".xsl"));
    } catch {
        const errorMessage = t("Could not fetch stylesheets from github");
        toast.error(errorMessage)
        return [];
    }
}


export const getStyleSheetData = async (
    download_url: string,
): Promise<Document | undefined> => {
    try {
        const { data: response } = await axios.get(download_url)
        return new DOMParser().parseFromString(response, "text/xml");
    } catch {
        const errorMessage = t("Could not download stylesheet");
        toast.error(errorMessage)
    }
}