interface FileSelectorProps {
    onFileSelected: (fileContent?: string) => void
    expectedFileType: string
}

export const FileSelector = (props: FileSelectorProps) => {

    function handleChange(selectorFiles?: FileList) {
        if (selectorFiles && selectorFiles.length === 1) {
            const reader = new FileReader()
            reader.onload = async (e) => {
                if (e.target) {
                    const text = (e.target.result)
                    props.onFileSelected(text as string);
                }
            };

            reader.readAsText(selectorFiles[0])
            return
        }

        props.onFileSelected(undefined);
    }

    return (
        <input
            id="file_upload"
            type="file"
            accept={props.expectedFileType}
            onChange={((e) => handleChange(e.target.files ?? undefined))}
            className="block w-full text-sm text-gray-500 border-2 rounded-lg border-gray-300
                file:mr-4 file:py-2 file:px-4 file:rounded-md
                file:border-0 file:text-sm file:text-gray-800
                file:bg-gray-200
                hover:file:bg-gray-300"
        />
    )
}