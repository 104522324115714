import { ChangeEvent } from "react";

interface TextAreaProps {
    handleChange: (event: ChangeEvent<HTMLTextAreaElement>) => void
    value: string
}

export const TextArea = (props: TextAreaProps) => {

    return (
        <div className="py-2">
            <textarea
                id="file_upload"
                value={props.value}
                onChange={((e) => props.handleChange(e))}
                rows={44}
                className="block w-full text-sm text-gray-500 border-2 rounded-lg border-gray-300"
            />
        </div >
    )
}